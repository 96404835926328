export const CONNECTION_CATEGORIES_LINKS = {
  outbound: [
    {
      name: "2G/3G",
      availableSystems: [
        { text: "2G/3G_AAA", routeName: "ic-2g-3g-aaa", },
      ],
    },

    {
      name: "4G",
      availableSystems: [
        { text: "AAA", routeName: "oc-4g-aaa", },
        { text: "ABMF", routeName: "oc-4g-abmf", },
        { text: "AF", routeName: "oc-4g-af", },
        { text: "EIR", routeName: "oc-4g-eir", },
        { text: "GMLC", routeName: "oc-4g-gmlc", },
        { text: "HSS", routeName: "oc-4g-hss", },
        { text: "MME", routeName: "oc-4g-mme", },
        { text: "OCF", routeName: "oc-4g-ocf", },
        { text: "OFCS", routeName: "oc-4g-ofcs", },
        { text: "PCRF", routeName: "oc-4g-pcrf", },
        { text: "PGW", routeName: "oc-4g-pgw", },
        { text: "SGW", routeName: "oc-4g-sgw", },
        { text: "SLF", routeName: "oc-4g-slf", },
        { text: "SPR", routeName: "oc-4g-spr", },
        { text: "ePDG", routeName: "oc-4g-epdg", },
      ],
    },

    {
      name: "5G",
      availableSystems: [
        { text: "5G-EIR", routeName: "oc-5g-eir", },
        { text: "AF", routeName: "oc-5g-af", },
        { text: "AMF", routeName: "oc-5g-amf", },
        { text: "AUSF", routeName: "oc-5g-ausf", },
        { text: "BSF", routeName: "oc-5g-bsf", },
        { text: "CHF", routeName: "oc-5g-chf", },
        { text: "DWDAF", routeName: "oc-5g-dwdaf", },
        { text: "GMLC", routeName: "oc-5g-gmlc", },
        { text: "HSS", routeName: "oc-5g-hss", },
        { text: "LMF", routeName: "oc-5g-lmf", },
        { text: "NEF", routeName: "oc-5g-nef", },
        { text: "NRF", routeName: "oc-5g-nrf", },
        { text: "NSSF", routeName: "oc-5g-nssf", },
        { text: "OTAF", routeName: "oc-5g-otaf", },
        { text: "PCF", routeName: "oc-5g-pcf", },
        { text: "SCEF", routeName: "oc-5g-scef", },
        { text: "SMF", routeName: "oc-5g-smf", },
        { text: "SMSF", routeName: "oc-5g-smsf", },
        { text: "UCMF", routeName: "oc-5g-ucmf", },
        { text: "UDM", routeName: "oc-5g-udm", },
        { text: "UDR", routeName: "oc-5g-udr", },
        { text: "VAE", routeName: "oc-5g-vae", },
      ],
    },

    {
      name: "Fixed Net",
      availableSystems: [
        { text: "BRAS", routeName: "oc-fixed-net-bras", },
      ],
    },

    {
      name: "Application",
      availableSystems: [
        { text: "SSH", routeName: "oc-application-ssh", },
        { text: "Selenium", routeName: "oc-application-selenium", },
      ],
    },

    {
      name: "Protocols",
      availableSystems: [
        { text: "Diameter", routeName: "oc-protocols-diameter", },
        { text: "HTTP", routeName: "og-protocols-http", },
        { text: "LDAP", routeName: "og-protocols-ldap", },
        { text: "RADIUS", routeName: "og-protocols-radius", },
      ],
    },
  ],

  inbound: [
    {
      name: "2G/3G",
      availableSystems: [
        { text: "2G/3G_AAA", routeName: "ic-2g-3g-aaa", },
        { text: "GGSN", routeName: "ic-2g-3g-ggsn", },
        { text: "UR", routeName: "ic-2g-3g-ur", },
      ],
    },

    {
      name: "4G",
      availableSystems: [
        { text: "AAA", routeName: "ic-4g-aaa", },
        { text: "ABMF", routeName: "ic-4g-abmf", },
        { text: "AF", routeName: "ic-4g-af", },
        { text: "EIR", routeName: "ic-4g-eir", },
        { text: "GMLC", routeName: "ic-4g-gmlc", },
        { text: "HSS", routeName: "ic-4g-hss", },
        { text: "MME", routeName: "ic-4g-mme", },
        { text: "OCF", routeName: "ic-4g-ocf", },
        { text: "OFCS", routeName: "ic-4g-ofcs", },
        { text: "PCRF", routeName: "ic-4g-pcrf", },
        { text: "PGW", routeName: "ic-4g-pgw", },
        { text: "SGW", routeName: "ic-4g-sgw", },
        { text: "SLF", routeName: "ic-4g-slf", },
        { text: "SPR", routeName: "ic-4g-spr", },
        { text: "ePDG", routeName: "ic-4g-epdg", },
      ],
    },

    {
      name: "5G",
      availableSystems: [
        { text: "5G-EIR", routeName: "ic-5g-eir", },
        { text: "AF", routeName: "ic-5g-af", },
        { text: "AMF", routeName: "ic-5g-amf", },
        { text: "AUSF", routeName: "ic-5g-ausf", },
        { text: "BSF", routeName: "ic-5g-bsf", },
        { text: "CHF", routeName: "ic-5g-chf", },
        { text: "DWDAF", routeName: "ic-5g-dwdaf", },
        { text: "GMLC", routeName: "ic-5g-gmlc", },
        { text: "HSS", routeName: "ic-5g-hss", },
        { text: "LMF", routeName: "ic-5g-lmf", },
        { text: "NEF", routeName: "ic-5g-nef", },
        { text: "NRF", routeName: "ic-5g-nrf", },
        { text: "NSSF", routeName: "ic-5g-nssf", },
        { text: "OTAF", routeName: "ic-5g-otaf", },
        { text: "PCF", routeName: "ic-5g-pcf", },
        { text: "SCEF", routeName: "ic-5g-scef", },
        { text: "SMF", routeName: "ic-5g-smf", },
        { text: "SMSF", routeName: "ic-5g-smsf", },
        { text: "UCMF", routeName: "ic-5g-ucmf", },
        { text: "UDM", routeName: "ic-5g-udm", },
        { text: "UDR", routeName: "ic-5g-udr", },
        { text: "VAE", routeName: "ic-5g-vae", },
      ],
    },

    {
      name: "Fixed Net",
      availableSystems: [
        { text: "BRAS", routeName: "ic-fixed-net-bras", },
      ],
    },

    {
      name: "Protocols",
      availableSystems: [
        { text: "Diameter", routeName: "ic-protocols-diameter", },
        { text: "HTTP", routeName: "ic-protocols-http", },
        { text: "LDAP", routeName: "ic-protocols-ldap", },
        { text: "RADIUS", routeName: "ic-protocols-radius", },
      ],
    },
  ],
};

export const OBJECTS_LINKS = [
  { text: "APN / B Numbers", routeName: "objects-apn-b-numbers" },
  { text: "Devices", routeName: "objects-devices" },
  { text: "Evaluation Parameters", routeName: "objects-evaluation-parameters" },
  { text: "HSS Objects", routeName: "objects-hss-objects" },
  { text: "LDAP Content", routeName: "objects-ldap-content" },
  { text: "SGW", routeName: "objects-sgw" },
  { text: "Subscriber Mobile", routeName: "objects-subscriber-mobile" },
  { text: "Subscriber SIP", routeName: "objects-subscriber-sip" }
];

export const DICTIONARY_LINKS = [
  { text: "RADIUS Attributes", routeName: "dictionary-radius-attributes" },
  { text: "Diameter Attributes", routeName: "dictionary-diameter-attributes" }
];
