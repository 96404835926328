<script>
import {computed, defineComponent, ref, watch} from 'vue';
import XDialog from '@/components/basic/XDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import userService from '@/js/services/UserService';
import {useSwitchContext} from '@/composition/user/use-switch-context';
import {useNotifications} from '@/composition/notifications';

export default defineComponent({
  name: 'ContextSwitcher',
  components: {LoadingDialog, XSelect, XDialog},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, {emit}) {
    const notifications = useNotifications();

    const organizationsWithProjects = ref([]);
    const {doContextSwitch} = useSwitchContext();

    userService.getOrganizationsWithProjects((orgList) => {
      orgList.sort((a, b) => a.name.localeCompare(b.name));

      const localOrganizationsWithProjects = [];
      const demoProjectId = process.env.VUE_APP_DEMO_PROJECT_ID;
      for (const org of orgList) {
        if (org?.description?.startsWith('demo')) {
          org.projects.push({
            id: parseInt(demoProjectId),
            name: 'Template and Demo Project',
          });
        }
        localOrganizationsWithProjects.push(org);
      }

      organizationsWithProjects.value = localOrganizationsWithProjects;

      if (organizationsWithProjects.value.length === 0) {
        notifications.showNotification({message: 'No organizations found. Please contact your administrator.'});
      }

      const organizationMatch = organizationsWithProjects.value.find(x => x.id === organization.value);
      if (!organizationMatch) {
        organization.value = organizationsWithProjects.value[0].id;
      }
    });

    const storedOrganizationString = localStorage.getItem('organization');
    const storedProjectString = localStorage.getItem('project');
    const storedLastProjectIdsString = localStorage.getItem('last-project-id');

    const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString).id : 0;
    const storedProject = storedProjectString ? JSON.parse(storedProjectString).id : 0;
    const storedLastProjectIds = storedLastProjectIdsString ? JSON.parse(storedLastProjectIdsString) : {};

    const organization = ref(storedOrganization);
    const project = ref(storedProject);

    const loading = ref(false);

    const switchContext = async () => {
      loading.value = true;

      try {
        await doContextSwitch({projID: project.value, orgID: organization.value});
        storedLastProjectIds[organization.value] = project.value;
        localStorage.setItem('last-project-id', JSON.stringify(storedLastProjectIds));
        location.reload();
      } finally {
        emit('input', false);
        loading.value = false;
      }
    };

    const buttons = [
      {
        text: 'Switch',
        icon: 'mdi-move-resize-variant',
        color: 'save',
        textColor: 'white',
        click: switchContext,
        disabled: () => organizationsWithProjects.value.length === 0,
      },
    ];

    const computedProjects = computed(() => {
      if (!organization.value || !organizationsWithProjects.value.length) {
        return [];
      }

      const organizationMatch = organizationsWithProjects.value.find(x => x.id === organization.value);
      if (!organizationMatch) {
        return organizationsWithProjects.value[0].projects;
      }

      return organizationMatch.projects;
    });

    watch(organization, (value) => {
      if (!value) {
        return;
      }

      if (organizationsWithProjects.value.length === 0) {
        return;
      }

      const organization = organizationsWithProjects.value.find(x => x.id === value);
      let projects = organizationsWithProjects.value[0].projects;
      if (organization) {
        projects = organization.projects;
      }

      if (storedLastProjectIds[value] && projects.find(x => x.id === storedLastProjectIds[value])) {
        project.value = parseInt(storedLastProjectIds[value]);
      } else {
        project.value = projects[0].id;
      }
    });

    return {
      organizationsWithProjects,
      organization,
      project,
      loading,
      buttons,
      computedProjects
    };
  },
});
</script>

<template>
  <XDialog
      :value="value"
      title="Switch Project"
      :buttons="buttons"
      @input="$emit('input', $event)"
      content-class="context-switcher-dialog">
    <template #dialog-content>
      <div>
        <div class="context-switcher-dialog-content">
          <div class="context-switcher-text">
            <div>
              Degust follows an organizational and project structure.
            </div>

            <div>
              An organization can be a company you work for. Your first organization was set up when you registered. You
              can
              change the settings of this organization in
              <router-link
                  :to="{ name: 'general-organization-settings' }"
                  @click.native="() => $emit('input', false)"
              >
                this
              </router-link>
              menu.
            </div>
            <div>
              Projects are test suites set up for specific purposes, such as the next release of a service on your
              network.
            </div>
            <div>
              You can work for different organizations/companies and there on different projects each. You have to
              switch to
              these projects via this dialog.
            </div>
          </div>
          <XSelect
              label="Organization"
              v-model="organization"
              :items="organizationsWithProjects"
              item-value="id"
              item-text="name"
              :disabled="organizationsWithProjects.length === 0"
              required/>
          <XSelect
              label="Project"
              v-model="project"
              :items="computedProjects"
              item-value="id"
              item-text="name"
              :disabled="organizationsWithProjects.length === 0"
              required/>
        </div>
        <LoadingDialog v-model="loading"/>
      </div>
    </template>
  </XDialog>
</template>

<style>
.context-switcher-dialog {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<style scoped>
.context-switcher-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.context-switcher-text {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  color: #666666;
}

.orga-settings-link {
  cursor: pointer;
  color: var(--v-primary-base);
}
</style>
