<script>
import { defineComponent, ref } from "vue";
import ConnectionsMenu from "@/components/specific/menu/ConnectionsMenu.vue";
import MenuLinkList from "@/components/specific/menu/MenuLinkList.vue";
import { useConfigurationMenu } from "@/composition/menu/use-configuration-menu";

export default defineComponent({
  name: "ConfigurationMenu",

  components: {
    MenuLinkList,
    ConnectionsMenu,
  },

  setup() {
    const activeTab = ref(0);

    const {
      tabs,
      objectsLinks,
      dictionaryLinks,
      computedOutboundCategories,
      computedInboundCategories,
      recentlyOutboundLinks,
      recentlyInboundLinks,
      handleConnectionsClick
    } = useConfigurationMenu();

    return {
      activeTab,
      tabs,
      computedInboundCategories,
      computedOutboundCategories,
      objectsLinks,
      dictionaryLinks,
      recentlyInboundLinks,
      recentlyOutboundLinks,
      handleConnectionsClick,
    };
  },
});
</script>

<template>
  <div v-show="tabs.length" class="configuration-menu">
    <v-tabs v-model="activeTab" right>
      <v-tab v-for="(tab, i) of tabs" :key="i" @mouseover="activeTab = i">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" class="configuration-menu-tabs-items">
      <v-tab-item>
        <ConnectionsMenu
          v-if="Object.keys(computedOutboundCategories).length"
          :categories="computedOutboundCategories"
          :recently-used="recentlyOutboundLinks"
          @navigation-click="(category, system) => handleConnectionsClick('outboundConnections', category, system)"
        />
      </v-tab-item>

      <v-tab-item v-if="$store.state.project.systemIds.length && Object.keys(computedInboundCategories).length">
        <ConnectionsMenu
          :categories="computedInboundCategories"
          :recently-used="recentlyInboundLinks"
          @navigation-click="(category, system) => handleConnectionsClick('inboundConnections', category, system)"
        />
      </v-tab-item>

      <v-tab-item>
        <div class="objects-menu">
          <MenuLinkList
            title="Objects"
            :links="objectsLinks"
          />

          <MenuLinkList
            title="Dictionary"
            :links="dictionaryLinks"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style scoped>
.configuration-menu {
  width: 1000px;
}

.objects-menu {
  display: flex;
  gap: 20px;
  padding: 0 30px 15px 30px;
}
</style>
